import { Top, Footer, Loading, Spinner } from '@src/share-component'
import React, { useRef, useState, memo }  from 'react';
import axios from 'axios'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './contact.css'
import '../styles/common.css'
import { Typography, useTheme } from '@material-ui/core';
import { useIntl } from "gatsby-plugin-react-intl"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { navigate } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Meta } from '@src/share-component'
import Sky from '@src/asset/skyscraper.jpg'

const required = true

const formItem = [
  { field : "name", required : required, multiline : false, length : 50, rows : 1, type : "text" },
  { field : "email", required : required, multiline : false, length : 50, rows : 1, type : "email" },
  { field : "company", required : required, multiline : false, length : 50, rows : 1, type : "text" },
  { field : "job", required : required, multiline : false, length : 50, rows : 1, type : "text"    },
  { field : "subject", required : required, multiline : false, length : 50, rows : 1, type : "text" },
  { field : "message", required : required , multiline : true, length : 250, rows : 2, type : "text" }
]

const Contact = () => {
  const intl = useIntl()
  const theme = useTheme()
  let spinner = null
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const [ message, setMessage ] = useState("")
  const [ name, setName ] = useState("")
  const [ email, setEmail ] = useState("")
  const [ subject, setSubject ] = useState("")
  const [ job, setJob ] = useState("")
  const [ company, setCompany ] = useState("")

  const submit = () => {
    const data = {
      name : name,
      email : email,
      company : company,
      job : job,
      subject : subject,
      message : message
    }
    axios.post(`${process.env.GATSBY_API_URL}/contact`, data)
      .then((res) => { 
        toast.success(intl.formatMessage({ id : 'contact-success'}), {
          onClose: () => navigate(`/${intl.locale}/`)
        });
        closeSpinner()
      })
      .catch((error) => { 
        toast.error(intl.formatMessage({ id : 'contact-error'}), {
          onClose: () => navigate(`/${intl.locale}/`)
        })
        closeSpinner()
        console.error(error)
      })

  }

  const closeSpinner = () => {
    document.body.removeChild(spinner)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    spinner = document.createElement('div');
    const ring = document.createElement('div');
    ring.classList.add('lds-dual-ring');
    spinner.classList.add('spinner')
    spinner.appendChild(ring)
    document.body.appendChild(spinner)
    submit()
  }

  const getValue = (key) => {
    switch (key) {
      case "message":
        return message
      case "job":
        return job
      case "subject":
        return subject
      case "company":
        return company
      case "name":
        return name
      case "email":
        return email

    }

  }

  const getSetter = (key) => {
    switch (key) {
      case "message":
        return setMessage
      case "job":
        return setJob
      case "subject":
        return setSubject
      case "company":
        return setCompany
      case "name":
        return setName
      case "email":
        return setEmail
    }
  }


  return <React.Fragment>
    <Meta
      title = {`${intl.formatMessage({ id : 'contact-title' })}`}
      description = {`${intl.formatMessage({ id : 'contact-description' })}`}
      slug = "/contact/"
     />
    <ToastContainer position = "top-center" hideProgressBar = {true}/>
    <Loading/>
    <div class = 'page'>
      <Top bg = {Sky} noSlogan = {true} useFullHeight = {true} imgID = 'contact-img'/>
      <div class = 'contact'>
        <Typography variant = 'h1' classes = {{ root : 'contact-title', h1 : "contact-title" }}>{intl.formatMessage({ id : 'contact-title' })}</Typography>
        <form className = "contact-form" autoComplete = "off" onSubmit = {onSubmit}>
          { 
            formItem.map(f => {
              return <TextField 
                required = { f.required }
                fullWidth = { true }
                type = { f.type }
                rowsMax = { f.rows }
                multiline = { f.multiline }
                value = { getValue(f.field) } 
                onChange = { (e) => getSetter(f.field)(e.target.value) }
                classes = {{ root : "contact-input", multiline : "contact-input-multiline" }}
                InputProps = {{ 
                  classes : { root : 'contact-input', underline : 'contact-input-underline', focused : 'contact-input-focused' },
                }}
                InputLabelProps	= {{
                  classes : { root : 'contact-label' }
                }}
                inputProps = {{
                  'aria-label': intl.formatMessage({ id : `contact-${f.field}`}),
                  // class : "contact-input-underline",
                  maxlength : f.length,
                  rows : f.rows
                }}
                label = {intl.formatMessage({ id : `contact-${f.field}`})}
                // placeholder = {intl.formatMessage({ id : `contact-${f.field}`})}
              />
            })
          }
          <Button
            classes = {{ root : "contact-submit-button", label : "contact-submit-label"}}
            type = "submit"
            fullWidth
            variant = "contained"
          >
            {intl.formatMessage({ id : 'contact-submit'})}
          </Button>
        </form>
        { matchesMD ? <Footer/> : null }
      </div>
        { matchesMD ? null : <Footer/> }
    </div>
  </React.Fragment>

}



export default memo(Contact)
